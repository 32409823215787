import React from "react"
import { Button, Typography } from "@material-ui/core"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/styles"

export default function Cta({ title, link, elevation, variant }) {
  const useStyles = makeStyles((theme) => ({
    link: {
      textDecoration: "none",
      color: "inherit",
      padding: "0.5rem",
    },
  }))
  const classes = useStyles()
  return (
    <Button
      elevation={elevation ? elevation : 5}
      size="large"
      fullWidth={true}
      variant={variant ? variant : "contained"}
      color="secondary"
    >
      <Link className={classes.link} to={`/${link}`}>
        <Typography variant="h5"> {title}</Typography>
      </Link>
    </Button>
  )
}
