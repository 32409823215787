import React from 'react'

import {makeStyles} from '@material-ui/core/styles'
import {Paper} from '@material-ui/core'


export default function Section(props){

    const useStyles=makeStyles(theme=>({
        section:{
            minHeight:'100%',
            width:'100%',
            marginTop:'2.5em',
            marginBottom:'2.5em',
            padding:'5rem 1rem',
            position:'relative',
            backgroundColor:props.color?theme.palette.background.custom:theme.palette.background.default,
            [theme.breakpoints.down('xs')]:{
                margin:0,
                padding:"1rem 0.5rem"
            }
        }
    }))
    const classes=useStyles();


return <Paper id={props.id} elevation={0} component='section' className={classes.section}>{props.children}</Paper>
}