import React from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Logo from "../../images/logocenter.svg"
import LogoLeft from "../../images/logoleft.svg"
import PhoneIcon from "@material-ui/icons/Phone"
import SendIcon from "@material-ui/icons/Send"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLess from "@material-ui/icons/ExpandLess"
import {
  AppBar,
  Drawer,
  List,
  ListItem,
  Toolbar,
  Hidden,
  Typography,
  Collapse,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
const slugify = require("@sindresorhus/slugify")
const useStyles = makeStyles(theme => ({
  list: {
    fontSize: "1.5rem",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit",
    width: "50vw",
  },
  fullList: {
    width: "auto",
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    transition: "all 0.5s ease-in-out",
    "&:hover": {
      "& > a": {
        transform: "scale(1.2,1.2)",
      },
      "& div ": {
        transform: "scale(1)",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "#e5e5e5",
      },
    },
  },
  listItemText: {
    padding: "0 !important",
  },
  navLink: {
    color: theme.palette.primary.white,
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "1.2rem",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    alignItems: "center",
    transition: "all 0.5s ease-in-out",
    "&::before,&::after": {
      boxSizing: "inherit",
      content: "",
      position: "absolute",
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      color: theme.palette.primary.fadedBlack,
      width: "100%",
      padding: "0.7rem 0",
      lineHeight: "20px",
      alignItems: "flex-left",
      marginLeft: "0",
      marginBottom: "0",
      marginTop: "0",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
  },
  navLinkActive: {
    color: "inherit",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  menuGrid: {
    display: "grid",
    gridTemplateColumns: "90vw 1fr 10vw",
    gridTemplateAreas: `"logo menu brand"`,
    padding: "0",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "20vw 1fr 20vw",
    },
  },
  logo: {
    gridArea: "logo",
    alignSelf: "center",
    justifySelf: "start",
    width: "100%",
    padding: "1rem",
    display: "flex",
    justifyContent: "start",
    "@media (min-width:1280px)": {
      gridTemplateColumns: "20vw 1fr 20vw",
      backgroundColor: "white",
    },
  },
  logoleft: {
    width: "30%",
    display: "inline-block",
    [theme.breakpoints.down("lg")]: {
      width: "30%",
    },
  },
  logoright: {
    width: "60%",
    display: "inline-block",
    [theme.breakpoints.down("lg")]: {
      width: "60%",
    },
  },
  brand: {
    display: "none",
    bottom: "0",
    left: "0",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    "& > div ": {
      width: "50%",
      marginLeft: "-8%",
    },
    "& > div > a > span": {
      fontSize: "0.72rem",
    },
    [theme.breakpoints.up("lg")]: {
      position: "relative",
      gridArea: "brand",
      display: "flex",
      alignSelf: "center",
      justifySelf: "center",
      textAlign: "center",
      gridColumn: "4/4",
      zIndex: "9",
      "& > div ": {
        width: "unset",
        marginLeft: "0",
      },
      "& > div > a > span": {
        fontSize: "1.2rem",
      },
    },
  },
  iconBox: {
    width: "3vw",
    height: "3vw",
    borderRadius: "50%",
    alignItems: "center",
    padding: "6px",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "0rem",
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("md")]: {
      marginRight: "1rem",
      fontSize: "2rem",
      backgroundColor: theme.palette.primary.white,
      justifyContent: "center",
      padding: "1px",
    },
  },
  menu: {
    gridArea: "menu",
    alignSelf: "center",
    justifySelf: "center",
    zIndex: "10",
    gridColumn: "2/4",
  },
  subMenu: {
    position: "relative",
    transition: "all 0.3s ease-in-out",
    "& > div": {
      position: "absolute",
      transform: "scale(0)",
      transition: "all 0.5s ease",
      top: "0",
      left: "0.5rem",
      height: "fit-content",
      width: "max-content",
      backgroundColor: theme.palette.secondary.main,
      display: "block",
    },
  },
  submenuIcon: {
    padding: 0,
    marginLeft: "-0.5rem",
    fontSize: "1.5rem",
    alignItems: "center",
    verticalAlign: "middle",
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.secondary.main,
  },
  subLink: {
    color: theme.palette.primary.white,
    position: "relative",
    padding: "0.9375rem 5px",
    fontWeight: "400",
    fontSize: "1rem",
    borderRadius: "3px",
    lineHeight: "10px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
    "&::before,&::after": {
      boxSizing: "inherit",
      content: "",
      position: "absolute",
      width: "100%",
      height: "100%",
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.fadedBlack,
    },
    [theme.breakpoints.down("xs")]: {
      color: theme.palette.primary.fadedBlack,
      width: "100%",
      padding: "0.7rem 0",
      lineHeight: "20px",
      alignItems: "flex-left",
      marginLeft: "0",
      marginBottom: "0",
      marginTop: "0",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
  },
  title: {
    fontSize: "1.05rem",
    padding: "0 5%",
    "@media (min-width: 768px)": {
      fontSize: "2rem",
      padding: 0,
    },
    "@media (min-width: 1100px)": {
      fontSize: "2.5rem",
      padding: 0,
    },
  },
  CloseButton: {
    position: "absolute",
    right: "15px",
    top: "15px",
    zIndex: "100",
  },
}))
export default function Menu({ items }) {
  const classes = useStyles()

  const [state, setState] = React.useState({
    left: false,
    right: false,
  })
  const [subMenu, setSubMenu] = React.useState(false)
  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setState({ ...state, [side]: open })
  }
  const toggleSubmenu = () => {
    setSubMenu(!subMenu)
  }

  const sideList = side => (
    <div className={classes.list} role="presentation">
      <List>
        {items.mainNavigation.map(item => (
          <ListItem
            style={
              item.submenu.length > 0
                ? { display: "grid", gridTemplateColumns: "1fr 1fr" }
                : {}
            }
            button
            key={item.page ? item.page.title : item.custom_name}
          >
            <Link
              to={item.slug.current==="/"?'/':`/${(item.slug.current).replace(/\s/g,"-").toLowerCase()}`}
              className={classes.navLink}
            >
              {item.page ? item.page.title : item.custom_name}
            </Link>
            {item.submenu.length > 0 ? (
              <>
                <div
                  style={{
                    marginLeft: "2rem",
                    position: "relative",
                    zIndex: "999",
                  }}
                  onClick={toggleSubmenu}
                  role="button"
                  tabIndex={0}
                  onKeyDown={toggleSubmenu}
                >
                  {subMenu ? <ExpandLess /> : <ExpandMoreIcon />}
                </div>
                <Collapse
                  style={{ width: "100%", gridColumn: "1/-1" }}
                  in={subMenu}
                >
                  {item.submenu.map((subItem, index) => (
                    <ListItem key={index}>
                      <Link
                        className={classes.subLink}
                        to={`/${slugify(subItem.title)}`}
                      >
                        {subItem.title}
                      </Link>
                    </ListItem>
                  ))}
                </Collapse>
              </>
            ) : (
              ""
            )}
          </ListItem>
        ))}
      </List>
    </div>
  )
  const fullList = () => (
    <List component="nav" className={classes.menu}>
      {items.mainNavigation.map(item => (
        <ListItem
          className={classes.listItem}
          key={item.page ? item.page.title : item.custom_name}
        >

          <Link
            to={item.slug.current==="/"?"/":`/${(item.slug.current).replace(/\s/g,"-").toLowerCase()}`}
            className={classes.navLink}
          >
            {item.page ? item.page.title : item.custom_name}
          </Link>

          {item.submenu.length > 0 ? (
            <>
              <ExpandMoreIcon className={classes.submenuIcon} />
              <List className={classes.subMenu}>
                {" "}
                <div>
                  {item.submenu.map((subItem, index) => (
                    <ListItem key={index}>
                      <Link
                        className={classes.subLink}
                        to={`/${slugify(subItem.title)}`}
                      >
                        {subItem.title}
                      </Link>
                    </ListItem>
                  ))}{" "}
                </div>
              </List>
            </>
          ) : (
            ""
          )}
        </ListItem>
      ))}
    </List>
  )

  return (
    <>
      <AppBar style={{ height: "max-content" }} color="primary">
        <Toolbar id="home" className={classes.menuGrid}>
          <div className={classes.logo}>
            <div className={classes.logoleft}>
              <LogoLeft />
            </div>
            <div className={classes.logoright}>
              <Logo />
            </div>
          </div>

          <Hidden smUp>
            <MenuIcon
              className={classes.menu}
              onClick={toggleDrawer("right", true)}
            />
          </Hidden>
          <Hidden xsDown>{fullList()}</Hidden>
          <div className={classes.brand}>
            <div>
              <a href={`tel:${items.contactphone}`} className={classes.navLink}>
                <div className={classes.iconBox}>
                  <PhoneIcon style={{ fontSize: "inherit" }} />
                </div>
                <Typography variant="h4" component="span">
                  Call Us {items.contactphone}
                </Typography>
              </a>
            </div>
            <div>
              <a
                href={`mailto:${items.contactemail}`}
                className={classes.navLink}
              >
                <div
                  className={classes.iconBox}
                  style={{ transform: "rotate(-45deg)", padding: "15px" }}
                >
                  <SendIcon style={{ fontSize: "inherit" }} />
                </div>
                <Typography variant="h4" component="span">
                  {items.contactemail}
                </Typography>
              </a>
            </div>

          </div>
        </Toolbar>
        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer("right", false)}
          variant="persistent"
        >
          <Typography
            className={classes.CloseButton}
            variant="h4"
            onClick={toggleDrawer("right", false)}
          >
            X
          </Typography>
          {sideList("right")}
        </Drawer>
      </AppBar>
      <Toolbar/>
    </>
  )
}
