import React from "react"
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from "@material-ui/core"
import BlockContent from "@sanity/block-content-to-react"
import Zoom from "react-reveal/Zoom"
import ListIcon from "@material-ui/icons/Check"
const useStyles=makeStyles(theme=>({
  text:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-evenly",
    alignItems:"start",
    height:"100%",
  }
}))

export default function TextBlock({ text="" }) {
  const classes=useStyles()
  const serializers = {
    list: props => {
      return (
        <Zoom>
          <List>{props.children}</List>
        </Zoom>
      )
    },
    listItem: props => {
      return (
        <ListItem>
          <ListItemIcon>
            <ListIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={props.children} />
        </ListItem>
      )
    },
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
      span: props => (
        <>
          <Typography variant="h1">{props.children}</Typography>
        </>
      ),
      block: props => {
        const { style = "normal" } = props.node
        if (/^h\d/.test(style)) {

          switch (style) {
            case "h1":
              return <Typography variant="h1"> {props.children}</Typography>
            case "h2":
              return <Typography variant="h2"> {props.children}</Typography>
            case "h3":
              return <Typography variant="h3"> {props.children}</Typography>
            case "h4":
              return <Typography variant="h4"> {props.children}</Typography>
            case "h5":
              return <Typography variant="h5"> {props.children}</Typography>
            case "h6":
              return <Typography variant="h6"> {props.children}</Typography>
            default: return
          }
        }

        if (style === "blockquote") {
          return <Typography variant="subtitle2" >{props.children}</Typography>
        }
        if (style === "normal") {
          return (
            <Typography variant="body1" style={{whiteSpace:"pre-wrap"}}>
             {props.children}
            </Typography>
          )
        }

        // Fall back to default handling
        return BlockContent.defaultSerializers.types.block(props)
      },
    },

    marks: {
      color: props => (
      <span style={{ color: props.mark.hex }}>{props.children}</span>
      )

    },
  }
return <>{text&&<BlockContent className={classes.text} blocks={text} serializers={serializers} />}</>
}
