import { useStaticQuery, graphql } from "gatsby"
export const useMapsData = () => {
  const { staticMap } = useStaticQuery(
    graphql`
    query MapsData {
        staticMap {
          mapUrl
          childFile {
            childImageSharp {
                fluid(maxHeight : 500, maxWidth:800){
               ...GatsbyImageSharpFluid
                }
            }
          }
        }
      }
    `
  )
  return staticMap
}