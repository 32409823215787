import React, { useState, useEffect } from "react"
import { makeStyles, Typography, Hidden } from "@material-ui/core"
import PhoneIcon from "@material-ui/icons/Phone"
import MessageIcon from "@material-ui/icons/Send"
export default function Fab({ contactphone, contactemail }) {
  const [hidden, setHidden] = useState(true)
  const useStyles = makeStyles(theme => ({
    fabContainer: {
      position: "fixed",
      bottom: 0,
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      flexWrap: "nowrap",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignContent: "center",
      width: "100%",
      height: "fit-content",
      padding: "1rem 0.1rem",

    "& > a":{
      display:"flex",
      flexDirection:"row",
      alignItems:"center",
      justifyContent:"space-evenly",
      textDecoration:"none"
    },},
    contacts: {
      fontSize:"0.8rem",
      display:"inline-block",
      textDecoration:"none",
      color:theme.palette.getContrastText(theme.palette.primary.main),
      [theme.breakpoints.up('sm')]:{
        fontSize:"1.5rem",
        paddingLeft:"0.2rem",
      }
    },
  }))
  const handlescroll = () => {
    const offset = window.pageYOffset

    if (offset > 40 && hidden) {
      setHidden(false)
    } else if (offset < 40 && !hidden) {
      setHidden(true)
    }
  }
  const classes = useStyles()
  const phone = `tel:${contactphone}`
  const mail = `mailto:${contactemail}`
  useEffect(() => {
    if (window.innerWidth < 1100)
      window.addEventListener("scroll", handlescroll)
    return () => {
      window.removeEventListener("scroll", handlescroll)
    }
  })
  return (
    <Hidden mdUp>
    <div className={classes.fabContainer}>
      <a href={phone}>
        <PhoneIcon color="secondary" fontSize="large" />
        <Typography variant="body2" className={classes.contacts}>{phone}</Typography>
      </a>

      <a href={mail}>
        <MessageIcon color="secondary" fontSize="large" />
        <Typography variant="body2" className={classes.contacts}>{contactemail}</Typography>
      </a>
    </div></Hidden>
  )
}
