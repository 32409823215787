import React from "react"
import Image from "gatsby-image"
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import Cta from "./cta"
import TextBlock from "../../lib/textblock"

const useStyles = makeStyles(theme => ({
  card: {
    height: "100%",
    width: "100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    paddingBottom: "1rem",
    "@media(min-width:1024px)": {
      width: "100%",
      height: "100%",
    },
  },
  cardHeader: {
    textAlign: "left",
    fontWeight: "600",
  },
  cardContent: {
    padding: "0 1rem",
  },
  subtext: {
    color: theme.palette.primary.fadedBlack,
    padding: "1rem 0",
  },
}))
export default function AthCard({ card }) {
  const classes = useStyles()
  return (
    <Card elevation={5} className={classes.card}>
<CardActionArea style={{cursor:"auto"}}>
        <Image fluid={card.image.asset.fluid} alt={card.image.alt} />
        <CardContent>
          <Typography variant="h4" component="span">
            {card.heading}
          </Typography>
          <TextBlock text={card.text._rawText} />
        </CardContent>
</CardActionArea>
      <CardActions>
       {card.cta.title&& <Cta title={card.cta.title} link={card.cta.route.slug.current} />}
      </CardActions>
    </Card>
  )
}
