import React from "react"
import { useMenuData } from "../lib/menudata"
import { AthFooter } from "./layout"
import Menu from "./layout/header"
import { ParallaxProvider } from "react-scroll-parallax"
import ScrollTop from "../components/layout/scrolltop"
import {
  MuiThemeProvider,
  createMuiTheme,
  CssBaseline,
  responsiveFontSizes,
} from "@material-ui/core"
import "../static/flaticon/font/flaticon.css"
import Fab from "./layout/fab"
import Helmet from "react-helmet"
const headerStyle = {
  fontFamily: ["Jura"],
  fontWeight: 700,

}

export default function PageLayout(props) {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }
  const menuData = useMenuData()

  const theme = createMuiTheme({
    palette: {
      type: "light",
      primary: {
        main: "#CCAE94",
        light: "#D1B399",
        white: "#fafafa",
        fadedBlack: "rgba(0, 0, 0, 0.9)",
      },
      secondary: {
        main: "#ce2029",
        background:'#ccae9433',
        fadedBlack:"rgba(0,0,0,0.5)"
      },
      background: {
        paper: "#fafafa",
        custom: '#ccae9433',
      },
    },

    typography: {
      fontFamily: ["Jura"],
      fontSize: 10,
      h1: headerStyle,
      h2:headerStyle,
      h3: headerStyle,
      h4: {
        fontWeight: "700",
      },
      h5: {
        fontWeight: "400",
      },
      h6: {
        fontWeight: "400",
      },
      body1: {
        fontSize: "1.5rem",
      },
      body2: {
        fontSize: 12,
      },
    },
  })

  return (

    <MuiThemeProvider theme={responsiveFontSizes(theme, { factor: 4 })}>
      <CssBaseline />
      <Helmet htmlAttributes={{lang:"en-nz"}}/>
      <Menu items={menuData} />
      <ParallaxProvider>
        <div id="top" style={{overflow:"hidden"}}>{props.children}</div>
      </ParallaxProvider>
      <ScrollTop />
      <Fab
        contactphone={menuData.contactphone}
        contactemail={menuData.contactemail}
      />
      <AthFooter />
    </MuiThemeProvider>
  )
}
