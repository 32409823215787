import React from "react"
import {useMapsData} from '../../lib/maps'
import Image from 'gatsby-image'
export default function Gmap({data}) {
const mapsData=useMapsData()
  return (
<div style={{width:"100%",height:"100%"}}>
      <Image fluid={mapsData.childFile.childImageSharp.fluid} alt="map image for businees location"/>
</div>
  )
}
