import React from 'react'
import {Typography, Link} from '@material-ui/core'
export default function Copyright() {
    return (
      <Typography variant="h6" style={{color:"black"}}  align="center">
        {'Website by '}
        <Link style={{color:"black"}} href="https://ath.nz/">
          all too human
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }