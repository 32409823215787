import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import Copyright from './copyright'
import CivilContractorsLogo from '../../images/civilContractorsLogo.svg'
const useStyles = makeStyles(theme => ({
  footerContainer: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",
    alignContent: "center",
    justifyContent: "center",
    padding:"1rem",
    gridGap:"1rem",
    justifyItems:"center",
    alignItems:"center",
    color:theme.palette.primary.fadedBlack,
    [theme.breakpoints.down('sm')]:{
     gridTemplateColumns:"auto",
     gridTemplateRows:"auto",
    },
  },
  company:{
    gridColumn:1,
    [theme.breakpoints.down('sm')]:{
      gridColumn:"1",
      gridRow:"1",
      alignSelf:"center",
      justifySelf:"center",
    },
  },
  address:{
    gridColumn:2,
    [theme.breakpoints.down('sm')]:{
      gridColumn:"1",
      gridRow:"2",
      alignSelf:"center",
      justifySelf:"center",
    },
  },
  socials: {
    [theme.breakpoints.down('sm')]:{
      gridColumn:"1",
      gridRow:"3",
      alignItems:"center",
    },
  },
  copyright:{
      alignContent:"bottom",
      color:theme.palette.secondary.main,
      fontSize:"1.5rem",
    [theme.breakpoints.down('sm')]:{
      gridRow:"4",
      gridColumn:"1",
    },
  }
}))
export default function Footer() {
  const classes = useStyles()
  return (
    <div className={classes.footerContainer}>
      <Typography className={classes.company} variant="h4">
        Chambers&Jackett Ltd.
        <br /> Contracting and Cartage
      </Typography>
      <Typography className={classes.address} variant="h5">229 Main Road, Lower Moutere<br/>03-526-7719<br/>admin@chambersandjackett.co.nz</Typography>
      <div className={classes.socials}>
       <CivilContractorsLogo/>
      </div>
      <div className={classes.copyright}>
      <Copyright/></div>
    </div>
  )
}
