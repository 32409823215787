import React,{useState,useCallback} from 'react'
import Masonry from 'react-masonry-component'
import { AthSection } from '.'
import {Typography,makeStyles} from '@material-ui/core'
import Carousel, { Modal, ModalGateway } from "react-images"
import Image from 'gatsby-image'


export default function MasonryGallery({data}){
  const fullSizeImages = []
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      width:"auto"
    },
    column: {
      backgroundClip: "padding-box",
      transition: "transform 0.01s",
    },
    thumbnail: {
      cursor: "pointer",
      padding:"1rem",
      userSelect: "none",
      position: "relative",
     
      width:"20%",
      "& img": {
        transition: "all 0.5s ease-in-out !important",
      },
      "&:hover": {
        "& img": {
          transform: "scale3d(1.2,1.2,1.2)",
        },
      },
      [theme.breakpoints.down('md')]:{
        width:"25%",
      },
      [theme.breakpoints.down('sm')]:{
        width:"50%",
      },
      [theme.breakpoints.down('xs')]:{
        width:"100%",
      }
    },
  }))
  const classes = useStyles()
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback(({ index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }
  data.fullWidth.forEach((image) => {
    fullSizeImages.push({
      source: image.asset.url,
    })
  })
  return(
    <AthSection>
      <Typography align="center" variant="h1" style={{marginBottom:"3rem"}}>
            {data.title}
          </Typography>
          <Typography variant="body1" align="center">
            {data.description}
          </Typography>
    <Masonry elementType="div" >
       {data.thumbnails.map((image, index) => (           
              <div
              key={index}
                className={classes.thumbnail}
                role="button"
                onKeyDown={openLightbox}
                onClick={openLightbox}
                tabIndex={0}
              >
                <Image fluid={image.asset.fluid} alt={image.alt} />
                {image.caption && (
                  <>
                    <span className={classes.textBG}>
                      <div className={classes.caption}>
                        <Typography variant="h4">{image.caption}</Typography>
                      </div>
                    </span>
                  </>
                )}
              </div>
            ))}
    </Masonry>
     <ModalGateway>
     {viewerIsOpen ? (
       <Modal onClose={closeLightbox}>
         <Carousel currentIndex={currentImage} views={fullSizeImages} />
       </Modal>
     ) : null}
   </ModalGateway>
 </AthSection>
  )
}

